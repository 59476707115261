/* # Navigation DESKTOP

Contains desktop specific definitions

Included in common-web.less

 */


@import '../variables.less';


/* # CONTENT

Content-holder definition - element that contains all included content

Can be deleted when IE8 support will be removed

 */
#content-holder {
	min-width: 758px;
	}

/* # AV sizer

Defines width of main elements - header, content, footer

 */

 /* #### General definition for all elements */
.AVsizer {
    max-width: 1056px;
	}


/* # BOTTOM LINKS

Standart element above footer, contains links

 */
#bottom-links {
	text-align: center;
	margin: 0;
	padding: 0;
	background-color: @BottomBackground;

	P {
		color: @BottomText;
	}
	
	A {	
	text-decoration: none;
	color: @BottomLink;
	font-size: inherit;
	
	 	&:hover {	
		color: @BottomLinkHover;
		}
	}

	UL {
		margin: 0;
		padding: 0;

		&:after {
			display: block;
			content: "";
			clear: both;
		}
		
		LI {
			list-style-type: none;
		}
	}

	/* # Internal links */
	.internal {
		padding-top: 60px;

		H4 {
			padding: 0 20px 15px 0;
			line-height: 1.43;
			font-size: 12px;
			font-weight: 400;
			color: @BottomTitle;
		
			A {
			 	color: @BottomTitle;							
			}
		}

		UL LI {

			A {
				font-size: 12px;
			}
		}


		/* #### region selector */
		.region-selector {

			[class*="span"] {
				padding-top: 62px;
				padding-bottom: 12px; 
			} 		

			 P.region {
				padding: 90px 0 0;
				background: transparent url("../i/avast-software-smaller-white.png") 0 0 no-repeat;
			
				/* region selector button */
				.region-selector-button {			
				    margin: 0 auto;
					zoom: 1;
					position: relative;
					display: inline-block;
					min-width: 280px;
					border-radius: 2px;
					box-shadow: 0px 0px 0px 1px @Grey-3;
					border: none\9; /* IE8 */
					font-size: 12px;
					font-weight: 400;
				    padding: 3px 30px 3px 3px;
				    transition: all 0.1s ease-in-out 0s;
				    background-color: @Transparent;

				    &:hover {
					    background-color: fadein(@Transparent, 10%);
					    }

				    /* flag */
					&:before {
						position: static;
					    background-color: transparent;
					    background-image: url("../i/flags/flag-language-selector-sprite-v10.png");
					    background-repeat: no-repeat;
					    content: "";
					    display: inline-block;
					    vertical-align: middle;
					    height: 20px;
					    width: 30px;
					    margin-right: 10px;
					}
					/* arrow */
					&:after {
					    position: absolute;
					    right: 10px;
					    top: 9px;
					    content: "";
					    display: inline-block;
					    width: 0.4em;
					    height: 0.4em;
					    border-right: 1px solid @Grey-9;
					    border-top: 1px solid @Grey-9;
					    transform: rotate(135deg);
					}				    					
				}
			}
		}

	

		/* #### navigation */
	    .navigation {

			.block {
			
				 &:nth-child(1),
				 &:nth-child(2) {
					height: 160px;
				}
				 &:nth-child(3),
				 &:nth-child(4) {
					height: 100px;
				}
				.line {
					border-left: 1px solid @BottomBorder;
					padding-left: 15px;
				}
				.locate {
					margin-top: 1.43em;
				}
			}

		}
	}

	/* # External links */

	.external {
		padding-bottom: 15px;

	 	UL LI {
		padding: 0;
		float: left;
		
		    
		}
		UL LI:last-child {
			margin-right: 0;
		}

	/* portals */
		.portals {
			UL { 
				LI {
				margin-right: 35px;

					A {
						padding-left: 40px;
					}
				}
				LI:last-child {
					margin-right: 0;
				}
			}
		}

	/* social container 

	Removing padding to make space for social icons active zones
	*/
	& > div:last-child {
		padding-left: 0;
		padding-right: 0;
	}	

	/* social */
		.social {
			width: auto;
			position: relative;

			UL {
				display: table;
				width: 100%;

				LI {
					display: table-cell;
					width: 16.6%;

					A {
						min-width: 30px;
						width: 100%;
					}
				}
			
				SPAN {
					position: fixed;
					left: 6000px; /* moves icon text outside viewport */
				}
			}
		}

	} 
}
	
/* # FOOTER

 * Copyright, EULA and privacy polocy links

 */
#footer {
	text-align: center;
	margin: 0;
	padding: 0;
	color: @FooterText;
	background-color: @FooterBackground;
	font-size: 11px;
	min-height: 100px;
    /* height: calc(100vh - 565px); */
	
	.AVsizer {
		height: 100px;
	    padding: 0;

	    &:before {
			content: "";
			display: block;
			height: 40px;
			overflow: hidden;
			border-top: 1px solid @FooterBorder;
		}
	}
	P {
		padding: 0;
		margin: 0;
		font-size: 12px;
	}	
	A {	
		text-decoration: none;
		color: @FooterLink;
		font-size: 12px;
		
			&:hover {	
			color: lighten(@FooterLink, 20%);
			}
	}
	P.links {
	float: left;
	
		A {
		display: inline-block;
		margin-right: 25px;
		}
	}
	P.copy {
	float: right;
	}
}


/* # Language selector

Styles for region selector

 */

/*  ## States  */

/* #### closed */
#lang-selector {
	display: none;	
}

/* #### opened */
#lang-selector.open {
	display: block;
	background-color: @RSBackground;
	z-index: 1100;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* ## Styling */
#lang-selector { 

	.selector-block {
	    max-width: 1056px;
	    height: 600px;
	    margin: 0 auto;
	    position: relative;
	    top: 50%;
	    margin-top: -300px;
	}
	.selector-block::after {
		content: "";
		display: block;
		clear: both;
	}
	.selector-close {
		position: absolute;
		right: 55px;
		top: 55px;
		background: transparent url("../i/i-v11/icons-sprite-nav-orange.png") no-repeat scroll 50% -1842px;
	    cursor: pointer;
	    height: 40px;
	    width: 40px;
	    text-align: center;
	    transition: all 0.4s ease-in-out 0s;
	    z-index: 2000;
	}

	.rs-region {
		float: left;
		height: 600px;
		position: relative;
	}
	.rs-region.america,
	.rs-region.asia {
		width: 25%;
	}
	.rs-region.europe {
		width: 50%;
	}

	H3 {
		font-size: 16px;
		color:  @RSTitle;
		margin: 0;
		padding: 0 0 20px 10px;
	}
	.rs-region H3 {
		text-transform: uppercase;
	}
	.recommended {
		float: right;
		display: none;
		}

	ul {
		padding: 0;
		margin: 0;

			li {
				position: relative;
				list-style:none;
				list-style-image:none;
				padding: 0;
				margin: 0;
				text-align: left;

				&:hover a {
					color: @Orange;
					}

				a {
					display:block; 
					text-align:left;
					padding:0;
					padding-left: 55px;
					line-height: 30px;
					color:  @RSText;
					text-decoration:none;	
					font-size: 13px;
					font-weight: 400;

					&:before {
						position: absolute;
						left: 12px;
						top: 50%;
						margin-top: -10px;
					}
				}
			}
		}
		
	ul#current-lang {
		color:#FFF;
		font-size:13px;
		text-align:left;
	}

	ul.possible-lang {
		float:left;
		color:#FFF;

		li a:before {
			content: "";
			width: 30px;
			height: 20px;
			display: inline-block;
			background-color: transparent;
			background-image: url(../i/flags/flag-language-selector-sprite-v10.png);
			background-repeat: no-repeat;
		}
	}

	/* space between columns in Europe */
	.europe ul.possible-lang.first {
		width: 50%;
	}

	/* rewrites for bottom countries */
	ul.possible-lang.other {
		float: none;
		position: absolute;
		left: 0;
		bottom: 0;
	}
	.rs-region UL LI.highlight A {
		color: @Orange;
	}


}


